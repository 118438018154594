import React, { useEffect, useState, useRef } from "react";
import {
    Grid, Box, Card, LinearProgress, TextField, CardContent, Typography, FormControl,
    InputLabel, Select, MenuItem, Autocomplete, CircularProgress, FormControlLabel, Checkbox, Dialog, FormGroup, DialogActions,
    DialogContent, DialogContentText, Modal, Menu, Fade, Stack
} from "@mui/material";
import Accordion from 'react-bootstrap/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Row, Col } from 'react-bootstrap';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import DownloadIcon from '@mui/icons-material/Download';
import API from "apiConfig";
import axios from "axios";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AltRoute } from "@mui/icons-material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MediaPlanExcelSheet from "./MediaPlanExcelSheet";
import MediaPlanExcelPreview from "./MediaPlanExcelPreview";
import { Link } from "react-router-dom";
import MediaPlanExcelNeat from "./MediaPlanExcelNeat";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function MediaPlanSheet() {
    const data = [
        { firstname: 'jill', lastname: 'smith', age: 22 },
        { firstname: 'david', lastname: 'warner', age: 23 },
        { firstname: 'nick', lastname: 'james', age: 26 },
      ];

    const [open, setOpen] = useState(false);
    const [showExcel, setShowExcel] = useState(false);
    const [showExcelP, setShowExcelP] = useState(false);
    const [showExcelNeat, setShowExcelNeat] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [itwSalesPocErr, setItwSalesPocErr] = useState(null);
    const [digitalPocErr, setDigitalPocErr] = useState(null);
    const [fileNameErr, setFileNameErr] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);
    const [middrollLiveList, setMiddrollLiveList] = useState(false);
    const [middrollCTVList, setMiddrollCTVList] = useState(false);
    const [middrollHighlightsList, setMiddrollHighlightsList] = useState(false);
    const [squeezeList, setSqueezeList] = useState(false);
    const [showTargetSub, setShowTargetSub] = useState(false);
    const [showDateInput, setShowDateInput] = useState(false);
    const [expandCol, setExpandCol] = useState(false);
    const [filteredBrandList, setFilteredBrandList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [filteredSportsSeriesList, setFilteredSportsSeriesList] = useState([]);
    const [sportsSeriesList, setSportsSeriesList] = useState([]);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentFormIndex, setCurrentFormIndex] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [eachOutlay, setEachOutlay] = useState(0);
    const [isSplitEdited, setIsSplitEdited] = useState(false);
    const [preRollData, setPreRollData] = useState({});
    const [preRollHighlightData, setPreRollHighlightData] = useState({});
    const [preRollCtvData, setPreRollCtvData] = useState({});
    const [midrollLiveData, setMidrollLiveData] = useState({});
    const [midrollCTVData, setMidrollCTVData] = useState({});
    const [midrollHighlightsData, setMidrollHighlightsData] = useState({});
    const [brandedCardData, setBrandedCardData] = useState({});
    const [squeezeUpData, setSqueezeUpData] = useState({});
    const [mastheadData, setMastheadData] = useState({});
    const [frameAdsData, setFrameAdsData] = useState({});
    const [fenceAdsData, setFenceAdsData] = useState({});
    const [socialBannerData, setSocialBannerData] = useState({});
    const [saveForm, setSaveForm] = useState({brandName: null, tournamentName: null, itwSalesPoc: null,
                region: "Bangalore", digitalPoc: null, fileName: null});
    const [brandDetails, setBrandDetails] = useState({
        brandName: null, brandId: null, outlay: null, tournamentName: null, tournamentId: null, startDate: null, endDate: null
    });
    const [errorDetails, setErrorDetails] = useState({
        brandName: null, outlay: null, tournamentName: null, startDate: null, endDate: null
    });
    const [forms, setForms] = useState([]);
    const [formsError, setFormsError] = useState([{
        id: 0, editLength: null, target: null, targetRegion: null, duration: null,
        startDate: null, endDate: null, discount: null, discountedCpm: null
    }]);
    const [allForms, setAllForms] = useState([{id: 0,outlay: brandDetails.outlay, forms: forms, expandDateCol: false, middrollLiveList: false, middrollCTVList: false,
        middrollHighlightsList: false, squeezeList: false, isSplitEdited: false, preRollData: {}, preRollHighlightData: {}, preRollCtvData: {}, midrollLiveData: {}, midrollCTVData: {},
        midrollHighlightsData: false, brandedCardData: {}, squeezeUpData: {}, mastHeadData: {}, frameAdsData: {}, fenceAdsData: {}, socialBannerData: {}, isSplitEdited: false}])
    const [openNeatDialog, setOpenNeatDialog] = React.useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [neatGrid, setNeatGrid] = useState({adFormat: true, editLength: true, target: true, duration: true,
        startDate: true, endDate: true, rateCardCpm: true, rateCardCpm10: true, discount: true, discountCpm: true,
        impressions: true, discountedOutlay: true, nonDiscountedOutlay: true, reach: true})
    // const toggleMiddrollCTVList = () => {
    //     setMiddrollCTVList(!middrollCTVList)
    //     setSqueezeList(false)
    //     setMiddrollLiveList(false)
    // }

    // const toggleMiddrollLiveList = () => {
    //     setMiddrollLiveList(!middrollLiveList)
    //     setSqueezeList(false)
    //     setMiddrollCTVList(false)
    // }

    // const toggleSqueezeList = () => {
    //     setMiddrollLiveList(false)
    //     setMiddrollCTVList(false)
    //     setSqueezeList(!squeezeList)
    // }

    const selectTarget = (e) => {
        setShowTargetSub(e.target.value);
        var targetVal = e.target.value;
        if (targetVal == "ROS") setShowTargetSub(true)
        else setShowTargetSub(false)
    }

    const selectDuration = (e) => {
        setShowDateInput(e.target.value);
        var durationVal = e.target.value;
        if (durationVal == "full_tournament") setShowDateInput(false)
        else setShowDateInput(true)
    }

    const expandColToggle = () => setExpandCol(!expandCol)

    useEffect(() => {
        // getPrerollDetails(0);
        getMidrollLiveDetails("10 Sec", 0)
            setBrandDetails({ ...brandDetails, outlay: 0 });
           const newArray = [...allForms[0].forms];
           let newForm = newArray.map(x => {
            return ({
                ...x, 
                outlay: getEachOutlay(0, x.split),
                impressions: getEachImpressions(getEachOutlay(0, x.split), x.discountedCpm),
                discountedOutlay: getEachDiscountedoutlay(getEachImpressions(getEachOutlay(0, x.split), x.discountedCpm), x.discountedCpm),
                nonDiscountedOutlay: getEachNonDiscountedoutlay(getEachImpressions(getEachOutlay(0, x.split), x.discountedCpm), x.rateCardCpm)
            })
        })
            let dataForms = [...allForms]
                let objIndex = dataForms.findIndex((obj => obj.id == 0));
                dataForms[objIndex].forms = newForm
                dataForms[objIndex].outlay = 0
                setAllForms(dataForms);
    }, []);

    function getEachImpressions(eachOutlay, discountedCpm) {
        let impressions = (Number(eachOutlay) === 0 || Number(discountedCpm) === 0) ? 0 : eachOutlay * 1000 / discountedCpm;
        return Math.round(impressions);
    }

    function getEachDiscountedoutlay(impressions, discountedCpm) {
        let discounted_outlay = (Number(impressions) === 0 || Number(discountedCpm) === 0) ? 0 : impressions * discountedCpm / 1000;
        return Math.round(discounted_outlay);
    }

    function getEachNonDiscountedoutlay(impressions, rateCardCpm) {
        let non_discounted_outlay = (Number(impressions) === 0 || Number(rateCardCpm) === 0) ? 0 : impressions * rateCardCpm / 1000;
        return Math.round(non_discounted_outlay);
    }

    function getEachOutlay(outlay, split) {
        let each_outlay = Number(outlay * (split / 100));;
        return each_outlay.toFixed(2);
    }

    function getEachDiscountCpm(eachOutlay, impressions) {
        let discount_cpm = (Number(eachOutlay) === 0 || Number(impressions) === 0) ? 0 : eachOutlay * 1000 / impressions;
        return discount_cpm.toFixed(2);
    }

    const onFormAddition = (data, sec, key, squeezeUpata, i) => {
        const forms = [...allForms[i].forms];
        let section_data = key === "PreRoll" ? (Object.keys(preRollData).length !== 0 ? preRollData : data) :
            key === "PreRoll Highlights" ? (Object.keys(preRollHighlightData).length !== 0 ? preRollHighlightData : data) :
                key === "PreRoll CTV" ? (Object.keys(preRollCtvData).length !== 0 ? preRollCtvData : data) :
                    key === "MidRoll Live - PPL" ? (Object.keys(midrollLiveData).length !== 0 ? midrollLiveData : data) :
                        key === "MidRoll CTV" ? (Object.keys(midrollCTVData).length !== 0 ? midrollCTVData : data) :
                            key === "MidRoll Highlights" ? (Object.keys(midrollHighlightsData).length !== 0 ? midrollHighlightsData : data) :
                                key === "Branded Cards" ? (Object.keys(brandedCardData).length !== 0 ? brandedCardData : data) :
                                    key === "Masthead" ? (Object.keys(mastheadData).length !== 0 ? mastheadData : data) :
                                        key === "Frame Ads" ? (Object.keys(frameAdsData).length !== 0 ? frameAdsData : data) :
                                            key === "Fence Ads" ? (Object.keys(fenceAdsData).length !== 0 ? fenceAdsData : data) :
                                                key === "Social Banner" ? (Object.keys(socialBannerData).length !== 0 ? socialBannerData : data) :
                                                    (Object.keys(squeezeUpData).length !== 0 ? squeezeUpData : data);
        let selectedTime = sec === "15 Sec" ? 15 : sec === "20 Sec" ? 20 : sec === "25 Sec" ? 25 : sec === "30 Sec" ? 30 : sec === "0 Sec" ? 0 : 10;
        let ros_cost = Number(selectedTime / 10) * Number(section_data?.ros?.cost);
        let ros_cost_10 = Number(10 / 10) * Number(section_data?.ros?.cost);
        let tg_cost = Number(selectedTime / 10) * Number(section_data?.targeted?.cost);
        let each_split = Number(100 / (forms.length + 1)) % 1 === 0 ? Math.round(Number(100 / (forms.length + 1))) : Math.round(Number(100 / (forms.length + 1)).toFixed(2));
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        let each_impressions = getEachImpressions(each_outlay, ros_cost);
        let discounted_outlay = getEachDiscountedoutlay(each_impressions, ros_cost);
        let non_discounted_outlay = getEachNonDiscountedoutlay(each_impressions, ros_cost);
        let newForm1 = forms.filter(item => item?.isImpressionEdited === false).map(x => { return ({ ...x, outlay: x.isSplitEdited === true ? x.outlay : each_outlay,
                                                                                                           split: x.isSplitEdited === true ? x.split : Math.round(each_split) }) });
        let newForm2 = isSplitEdited ? forms.filter(item => item?.isImpressionEdited === true) :
            forms.filter(item => item?.isImpressionEdited === true).map(x => { return ({ ...x, outlay: x.isSplitEdited === true ? x.outlay : each_outlay,
                                                                                               split: x.isSplitEdited === true ? x.split : Math.round(each_split) }) });
        let newForm3 = newForm1.concat(newForm2).sort(function (a, b) {
            return a.id - b.id
        });
       // console.log("newForm3",newForm3)
        let newForm = newForm3.map(x => {
            return ({
                ...x, impressions: x.adFormat === "Squeeze Up" ? 1 : getEachImpressions(x.outlay, x.discountedCpm),
                discountedOutlay: x.adFormat === "Squeeze Up" ? x.discountedCpm : getEachDiscountedoutlay(getEachImpressions(x.outlay, x.discountedCpm), x.discountedCpm),
                nonDiscountedOutlay: x.adFormat === "Squeeze Up" ? x.rateCardCpm : getEachNonDiscountedoutlay(getEachImpressions(x.outlay, x.discountedCpm), x.rateCardCpm)
            })
        })
        setForms([...newForm, {
            id: forms.length, adFormat: key, 
            editLength: key === "Branded Cards" ? "NA" : key === "Squeeze Up" ? squeezeUpata : sec,
            target: "ROS", targetRegion: "", showTargetRegion: false,
            duration: "full_tournament", showDateInput: false, startDate: startDate, endDate: endDate,
            rateCardCpm: ros_cost, 
            rateCardCpm10: key === "Branded Cards" ? " - " : key === "Squeeze Up" ? " - " : ros_cost_10,
            outlay: each_outlay, discount: 0, discountedCpm: ros_cost,
            impressions: key === "Squeeze Up" ? 1 : getEachImpressions(each_outlay, ros_cost),
            discountedOutlay: key === "Squeeze Up" ? ros_cost : discounted_outlay, 
            nonDiscountedOutlay: key === "Squeeze Up" ? ros_cost: non_discounted_outlay,
            isImpressionEdited: false, isSplitEdited: false, split: Math.round(each_split),
            customDuration : "", reach: "", optionPackageName: "Inventory Buy Package", optionHeading: "Option"
        }]);
        // let formsData = allForms.map(x=> {return ({...x,
        //     outlay: x.outlay !== null ? x.outlay : brandDetails.outlay, forms: [...newForm, {
        //     id: forms.length, adFormat: key, 
        //     editLength: key === "Branded Cards" ? "NA" : key === "Squeeze Up" ? squeezeUpata : sec,
        //     target: "ROS", targetRegion: "", showTargetRegion: false,
        //     duration: "full_tournament", showDateInput: false, startDate: startDate, endDate: endDate,
        //     rateCardCpm: ros_cost, outlay: each_outlay, discount: 0, discountedCpm: ros_cost,
        //     impressions: key === "Squeeze Up" ? 1 : getEachImpressions(each_outlay, ros_cost),
        //     discountedOutlay: key === "Squeeze Up" ? ros_cost : discounted_outlay, 
        //     nonDiscountedOutlay: key === "Squeeze Up" ? ros_cost: non_discounted_outlay,
        //     isImpressionEdited: false, split: each_split
        // }]})})

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = [...newForm, {
            id: forms.length, adFormat: key, 
            editLength: key === "Branded Cards" ? "NA" : key === "Squeeze Up" ? squeezeUpata : sec,
            target: "ROS", targetRegion: "", showTargetRegion: false,
            duration: "full_tournament", showDateInput: false, startDate: startDate, endDate: endDate,
            rateCardCpm: ros_cost, 
            rateCardCpm10: key === "Branded Cards" ? " - " : key === "Squeeze Up" ? " - " : ros_cost_10,
            outlay: each_outlay, discount: 0, discountedCpm: ros_cost,
            impressions: key === "Squeeze Up" ? 1 : getEachImpressions(each_outlay, ros_cost),
            discountedOutlay: key === "Squeeze Up" ? ros_cost : discounted_outlay, 
            nonDiscountedOutlay: key === "Squeeze Up" ? ros_cost: non_discounted_outlay,
            isImpressionEdited: false,isSplitEdited: false, split: Math.round(each_split),
            customDuration : "", reach: "", optionPackageName: "Inventory Buy Package", optionHeading: "Option"
        }]
        dataForms[objIndex].outlay = allForms[i].outlay !== null ? allForms[i].outlay : brandDetails.outlay
        setAllForms(dataForms);

       // setAllForms(formsData)
        setFormsError([...formsError, {
            id: forms.length, editLength: null, target: null, targetRegion: null, duration: null,
            startDate: null, endDate: null, rateCardCpm: null, discount: null, discountedCpm: null, nonDiscountedOutlay: null,
            impressions: null
        }])
        setEachOutlay(Number(brandDetails.outlay / (forms.length + 1)).toFixed(2));
        setIsSplitEdited(false);
    }
    const onFormDelete = (index, i) => {
        handleClose1();
       // console.log("index",index,"i",i)
        const newArray = [...allForms[i].forms];
        let ros_cost = Number(preRollData?.ros?.cost);
        let tg_cost = Number(preRollData?.targeted?.cost);
        //  let form = forms;
        let newForm = newArray.filter(item => item.id !== index);
        let each_split = Number(100 / (newForm.length)) % 1 === 0 ? Math.round(Number(100 / (newForm.length))) : Math.round(Number(100 / (newForm.length)).toFixed(2));
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        let each_impressions = Number(each_outlay * 1000 / ros_cost).toFixed(2);
        let discounted_outlay = Number(each_impressions * ros_cost / 1000).toFixed(2);
        let non_discounted_outlay = Number(each_impressions * ros_cost / 1000).toFixed(2);

        let oneMore = newForm.map((x, index) => {
            return ({
                ...x,
                id: index,
                split: x.isSplitEdited === true ? x.split : Math.round(each_split),
                outlay: x.isSplitEdited === true ? x.outlay : each_outlay,
                impressions: getEachImpressions(x.isSplitEdited === true ? x.outlay : each_outlay, x.discountedCpm),
                discountedOutlay: getEachDiscountedoutlay(getEachImpressions(x.isSplitEdited === true ? x.outlay : each_outlay, x.discountedCpm), x.discountedCpm),
                nonDiscountedOutlay: getEachNonDiscountedoutlay(getEachImpressions(x.isSplitEdited === true ? x.outlay : each_outlay, x.discountedCpm), x.rateCardCpm)
            })
        })
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = oneMore
        setAllForms(dataForms);
       // setForms(oneMore);

        let newFormError = formsError.filter(item => item.id !== index);
        let oneMoreError = newFormError.map((x, index) => { return ({ ...x, id: index }) })
        setFormsError(oneMoreError);
        let eachIndex = forms.length === 1 ? 2 : forms.length
        setEachOutlay(Number(brandDetails.outlay / (eachIndex - 1)).toFixed(2));
        setIsSplitEdited(false);
    }

    const onFormReset = (index, i) => {
        handleClose1();
        //const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        let section_data = newArray[index].adFormat === "PreRoll" ? preRollData :
            newArray[index].adFormat === "PreRoll Highlights" ? preRollHighlightData :
                newArray[index].adFormat === "PreRoll CTV" ? preRollCtvData :
                    newArray[index].adFormat === "MidRoll Live - PPL" ? midrollLiveData :
                        newArray[index].adFormat === "MidRoll CTV" ? midrollCTVData :
                            newArray[index].adFormat === "MidRoll Highlights" ? midrollHighlightsData :
                                newArray[index].adFormat === "Branded Cards" ? brandedCardData :
                                    newArray[index].adFormat === "Masthead" ? mastheadData :
                                        newArray[index].adFormat === "Frame Ads" ? frameAdsData :
                                            newArray[index].adFormat === "Fence Ads" ? fenceAdsData :
                                                newArray[index].adFormat === "Social Banner" ? socialBannerData : squeezeUpData;
        let sec = newArray[index].editLength;
        let selectedTime = sec === "15 Sec" ? 15 : sec === "20 Sec" ? 20 : sec === "25 Sec" ? 25 : sec === "30 Sec" ? 30 : sec === "0 Sec" ? 0 : 10;
        let ros_cost = Number(selectedTime / 10) * Number(section_data?.ros?.cost);
        let tg_cost = Number(selectedTime / 10) * Number(section_data?.targeted?.cost);
        let ros_cost_10 = Number(10 / 10) * Number(section_data?.ros?.cost);
        let rateCard_cpm = ros_cost;
        let discount = 0;
        let discountAmount = (rateCard_cpm / 100) * discount;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        // let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        let edit_length = newArray[index].adFormat === "Branded Cards" ? "NA" : newArray[index].adFormat === "Squeeze Up" ? "Super 4's" : sec

        newArray[index] = {
            ...newArray[index], editLength: edit_length, target: "ROS", discount: 0,
            rateCardCpm: ros_cost, discountedCpm: discountCpm,
            impressions: each_impressions, targetRegion: "",
            showTargetRegion: false, duration: "full_tournament",
            showDateInput: false, startDate: startDate, endDate: endDate,
            rateCardCpm10: newArray[index].adFormat === "Branded Cards" ? " - " : newArray[index].adFormat === "Squeeze Up" ? " - " : ros_cost_10, 
            outlay: each_outlay,
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay,
            isImpressionEdited: false, split: Math.round(each_split),
            customDuration : "", reach: ""
        }
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
       // setForms(newArray);
    }

    //function to get brand list
    const getBrandList = async (data) => {
        try {
            let input = (data !== undefined && data !== null) ? data : ""
            const response = await API.get(`brand/get_brands_list/${input}`);
            if (response.data.status) {
                let data = response.data.data.map(item => item.brand_name);
                setBrandList([]);
                setFilteredBrandList([]);
                setBrandList(data);
                setFilteredBrandList(response.data.data);
            }
            else {
                console.log("Error")
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

       //function to save new form
       const onSaveNewForm = async () => {
        let salesErr = (saveForm.itwSalesPoc === null || saveForm.itwSalesPoc === "") ? "Please enter ITW sales POC" : null;
        let digitalErr = (saveForm.digitalPoc === null || saveForm.digitalPoc === "") ? "Please enter Digital-360 POC" : null;
        let fileNameErr = (saveForm.fileName === null || saveForm.fileName === "") ? "Please enter file name" : null;

        if(salesErr || digitalErr || fileNameErr){
            setItwSalesPocErr(salesErr);
            setDigitalPocErr(digitalErr);
            setFileNameErr(fileNameErr);
            return
        }
        else{
        setFormLoading(true);
        try {
            let dataToSend = {
                        "Forms": allForms.map(x=> x.forms.map(y=> {return ({...y, wholeOutlay: x.outlay})})),
                        "Details": {...saveForm, brandID: Number(brandDetails.brandId), tournamentID: Number(brandDetails.tournamentId)}
                    }
            const response = await API.post('media_planning/createMediaPlan',dataToSend);
            if (response.data.status) {
                setFormLoading(false);
                setOpen(false);
                alert("Form saved successfully.")
              //  setSaveForm({...saveForm, fileName: null})
            }
            else {
                console.log("Error")
                setFormLoading(false);
                setOpen(false);
                alert("Something went wrong.\nPlease try again after some time")
            }
        }
        catch (error) {
            setFormLoading(false);
            setOpen(false);
                alert("Something went wrong.\nPlease try again after some time")
        }
        }
    }

    const closeMenu = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].middrollLiveList = false
        dataForms[objIndex].middrollCTVList = false
        dataForms[objIndex].middrollHighlightsList = false
        dataForms[objIndex].squeezeList = false
        setAllForms(dataForms);
    }

    const handleMidrollLiveListShow = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].middrollLiveList = !dataForms[objIndex].middrollLiveList
        dataForms[objIndex].middrollCTVList = false
        dataForms[objIndex].middrollHighlightsList = false
        dataForms[objIndex].squeezeList = false
        setAllForms(dataForms);
    }

    const handleMidrollCTVListShow = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].middrollCTVList = !dataForms[objIndex].middrollCTVList
        dataForms[objIndex].middrollLiveList = false
        dataForms[objIndex].middrollHighlightsList = false
        dataForms[objIndex].squeezeList = false
        setAllForms(dataForms);
    }

    const handleMidrollHighlightsListShow = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].middrollHighlightsList = !dataForms[objIndex].middrollHighlightsList
        dataForms[objIndex].middrollLiveList = false
        dataForms[objIndex].middrollCTVList = false
        dataForms[objIndex].squeezeList = false
        setAllForms(dataForms);
        
    }

    const handleSqueezeListShow = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].squeezeList = !dataForms[objIndex].squeezeList
        dataForms[objIndex].middrollLiveList = false
        dataForms[objIndex].middrollCTVList = false
        dataForms[objIndex].middrollHighlightsList = false
        setAllForms(dataForms);
    }

    const handleExpandDate = (i) => {
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].expandDateCol = !dataForms[objIndex].expandDateCol
        setAllForms(dataForms);
    }

    const handleFillAllDiscounts = (i) => {
        const forms = [...allForms[i].forms];
        let firstDiscount = forms[0].discount;
        // let rateCard_cpm = ros_cost;
        // let discountAmount = (rateCard_cpm / 100) * newArray[index].discount;
        // let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        // let each_split = Math.round(newArray[index].split);
        // let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        // //let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        // let each_impressions = getEachImpressions(each_outlay, discountCpm);
        // let discounted_outlay = getEachDiscountedoutlay(each_impressions, discountCpm);
        // let non_discounted_outlay = getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        let newArray = forms.map(x=> {return(
                {...x, 
                discount: firstDiscount,
                discountedCpm: (x.rateCardCpm - (x.rateCardCpm / 100) * firstDiscount).toFixed(2),
                impressions: getEachImpressions(x.outlay, x.rateCardCpm - (x.rateCardCpm / 100) * firstDiscount),
                discountedOutlay: getEachDiscountedoutlay(getEachImpressions(x.outlay, x.rateCardCpm - (x.rateCardCpm / 100) * firstDiscount), x.rateCardCpm - (x.rateCardCpm / 100) * firstDiscount),
                nonDiscountedOutlay: getEachNonDiscountedoutlay(getEachImpressions(x.outlay, x.rateCardCpm - (x.rateCardCpm / 100) * firstDiscount), x.rateCardCpm),
                isImpressionEdited: true
                })})
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
    }

    //function to get Preroll Details
    const getPrerollDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "PreRoll").length > 0) {
            onFormAddition(null, "10 Sec", "PreRoll", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=PreRoll`);
                if (response.data.status) {
                    setPreRollData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "PreRoll", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

     //function to get Preroll Highlights Details
     const getPrerollHighlightDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "PreRoll Highlights").length > 0) {
            onFormAddition(null, "10 Sec", "PreRoll Highlights", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=PreRoll Highlights`);
                if (response.data.status) {
                    setPreRollHighlightData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "PreRoll Highlights", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

     //function to get Preroll CTV Details
     const getPrerollCtvDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "PreRoll CTV").length > 0) {
            onFormAddition(null, "10 Sec", "PreRoll CTV", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=PreRoll CTV`);
                if (response.data.status) {
                    setPreRollCtvData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "PreRoll CTV", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get MidrollLive Details
    const getMidrollLiveDetails = async (sec, i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "MidRoll Live - PPL").length > 0) {
            onFormAddition(null, sec, "MidRoll Live - PPL", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=MidRoll Live - PPL`);
                if (response.data.status) {
                    setMidrollLiveData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, sec, "MidRoll Live - PPL", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get MidrollCTV Details
    const getMidrollCtvDetails = async (sec, i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "MidRoll CTV").length > 0) {
            onFormAddition(null, sec, "MidRoll CTV", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=MidRoll CTV`);
                if (response.data.status) {
                    setMidrollCTVData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, sec, "MidRoll CTV", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

     //function to get Midroll Highlights Details
     const getMidrollHighlightsDetails = async (sec, i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "MidRoll Highlights").length > 0) {
            onFormAddition(null, sec, "MidRoll Highlights", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=MidRoll Highlights`);
                if (response.data.status) {
                    setMidrollHighlightsData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, sec, "MidRoll Highlights", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Branded Card Details
    const getBrandedCardDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Branded Cards").length > 0) {
            onFormAddition(null, "10 Sec", "Branded Cards", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Branded Cards`);
                if (response.data.status) {
                    setBrandedCardData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "Branded Cards", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Squeeze Up Details
    const getSqueezeUpDetails = async (input, data, i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Squeeze Up").length > 0) {
            onFormAddition(null, "0 Sec", "Squeeze Up", data, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Squeeze Up`);
                if (response.data.status) {
                    setSqueezeUpData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "0 Sec", "Squeeze Up", data, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Masthead Details
    const getMastheadDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Masthead").length > 0) {
            onFormAddition(null, "10 Sec", "Masthead", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Masthead`);
                if (response.data.status) {
                    setMastheadData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "Masthead", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Frame Ads Details
    const getFrameAdsDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Frame Ads").length > 0) {
            onFormAddition(null, "10 Sec", "Frame Ads", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Frame Ads`);
                if (response.data.status) {
                    setFrameAdsData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "Frame Ads", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Fence Ads Details
    const getFenceAdsDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Fence Ads").length > 0) {
            onFormAddition(null, "10 Sec", "Fence Ads", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Fence Ads`);
                if (response.data.status) {
                    setFenceAdsData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "Fence Ads", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get Social Banner Details
    const getSocialBannerDetails = async (i) => {
        const forms = [...allForms[i].forms];
        if (forms.filter(x => x.adFormat === "Social Banner").length > 0) {
            onFormAddition(null, "10 Sec", "Social Banner", null, i);
        }
        else {
            try {
                const response = await API.get(`series/rate_card?video_inv=Social Banner`);
                if (response.data.status) {
                    setSocialBannerData(response.data.data.data.standalone)
                    onFormAddition(response.data.data.data.standalone, "10 Sec", "Social Banner", null, i);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    //function to get sportsSeries list
    const getSportsSeries = async (data) => {
        try {
            let input = (data !== undefined && data !== null) ? data : ""
            const response = await API.get(`series/view_sport_series/${input}`);
            if (response.data.status) {
                // console.log("Response",response.data.data);
                let data = response.data.data.data.map(item => item.series_name);
                setSportsSeriesList([]);
                setFilteredSportsSeriesList([]);
                setSportsSeriesList(data);
                setFilteredSportsSeriesList(response.data.data.data);
            }
            else {
                console.log("Error")
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
            // console.log("Error",error)
        }
    }

    const handleInputChange = (event, index, i) => {
        const newArray = [...allForms[i].forms];
        let section_data = newArray[index].adFormat === "PreRoll" ? preRollData :
            newArray[index].adFormat === "PreRoll Highlights" ? preRollHighlightData :
                newArray[index].adFormat === "PreRoll CTV" ? preRollCtvData :
                    newArray[index].adFormat === "MidRoll Live - PPL" ? midrollLiveData :
                        newArray[index].adFormat === "MidRoll CTV" ? midrollCTVData :
                            newArray[index].adFormat === "MidRoll Highlights" ? midrollHighlightsData :
                                newArray[index].adFormat === "Branded Cards" ? brandedCardData :
                                    newArray[index].adFormat === "Masthead" ? mastheadData :
                                        newArray[index].adFormat === "Frame Ads" ? frameAdsData :
                                            newArray[index].adFormat === "Fence Ads" ? fenceAdsData :
                                                newArray[index].adFormat === "Social Banner" ? socialBannerData : squeezeUpData;
        let sec = event.target.value;
        let selectedTime = sec === "15 Sec" ? 15 : sec === "20 Sec" ? 20 : sec === "25 Sec" ? 25 : sec === "30 Sec" ? 30 : sec === "0 Sec" ? 0 : 10;
        let ros_cost = Number(selectedTime / 10) * Number(section_data?.ros?.cost);
        let tg_cost = Number(selectedTime / 10) * Number(section_data?.targeted?.cost);
        let tg2_cost = Number(selectedTime / 10) * Number(section_data?.targeted2?.cost);
        let ros_cost_10 = Number(10 / 10) * Number(section_data?.ros?.cost);

        let rateCard_cpm = ros_cost;
        let discountAmount = (rateCard_cpm / 100) * newArray[index].discount;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        //let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);

        newArray[index] = {
            ...newArray[index], editLength: sec, 
            rateCardCpm: newArray[index].target == "TG1" ? tg_cost : newArray[index].target == "TG2" ? tg2_cost : ros_cost, 
            rateCardCpm10: newArray[index].adFormat === "Branded Cards" ? " - " : newArray[index].adFormat === " - " ? "Super 4's" : ros_cost_10, 
            outlay: each_outlay, discountedCpm: discountCpm,
            impressions: newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : each_impressions,
            discountedOutlay: newArray[index].adFormat === "Squeeze Up" ? discountCpm : discounted_outlay,
            nonDiscountedOutlay: newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : non_discounted_outlay,
            isImpressionEdited: true
        }

       // setForms(newArray);
    //    console.log("newArray2",newArray)
    let dataForms = [...allForms]
    let objIndex = dataForms.findIndex((obj => obj.id == i));
    dataForms[objIndex].forms = newArray
    setAllForms(dataForms);
    console.log("dataForms",dataForms)
    }

    const handleTargetChange = (event, index, i) => {
       // const newArray = [...forms];
       const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];
        let section_data = newArray[index].adFormat === "PreRoll" ? preRollData :
            newArray[index].adFormat === "PreRoll Highlights" ? preRollHighlightData :
                newArray[index].adFormat === "PreRoll CTV" ? preRollCtvData :
                    newArray[index].adFormat === "MidRoll Live - PPL" ? midrollLiveData :
                        newArray[index].adFormat === "MidRoll CTV" ? midrollCTVData :
                            newArray[index].adFormat === "MidRoll Highlights" ? midrollHighlightsData :
                                newArray[index].adFormat === "Branded Cards" ? brandedCardData :
                                    newArray[index].adFormat === "Masthead" ? mastheadData :
                                        newArray[index].adFormat === "Frame Ads" ? frameAdsData :
                                            newArray[index].adFormat === "Fence Ads" ? fenceAdsData :
                                                newArray[index].adFormat === "Social Banner" ? socialBannerData : squeezeUpData;
        let sec = newArray[index].editLength;
        let selectedTime = sec === "15 Sec" ? 15 : sec === "20 Sec" ? 20 : sec === "25 Sec" ? 25 : sec === "30 Sec" ? 30 : sec === "0 Sec" ? 0 : 10;
        var targetVal = event.target.value;
        let ros_cost = Number(selectedTime / 10) * Number(section_data?.ros?.cost);
        let tg_cost = Number(selectedTime / 10) * Number(section_data?.targeted?.cost);
        let tg2_cost = Number(selectedTime / 10) * Number(section_data?.targeted2?.cost);
        let ros_cost_10 = Number(10 / 10) * Number(section_data?.ros?.cost);
        let tg_cost_10 = Number(10 / 10) * Number(section_data?.targeted?.cost);
        let tg2_cost_10 = Number(10 / 10) * Number(section_data?.targeted?.cost);

        newArray[index] = {
            ...newArray[index], target: targetVal,
            showTargetRegion: (targetVal == "TG1" || targetVal == "TG2") ? true : false,
            targetRegion: (targetVal == "TG1" || targetVal == "TG2") ? "Top 6 Metros" : "",
            rateCardCpm: targetVal == "TG1" ? tg_cost : targetVal == "TG2" ? tg2_cost : ros_cost,
            rateCardCpm10: newArray[index].adFormat === "Branded Cards" ? " - " : newArray[index].adFormat === " - " ? "Super 4's" : targetVal == "TG1" ? tg_cost_10 : targetVal == "TG2" ? tg2_cost_10 : ros_cost_10
        }

        let rateCard_cpm = newArray[index].rateCardCpm;
        let discountAmount = (rateCard_cpm / 100) * newArray[index].discount;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        //let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);

        newArray[index] = {
            ...newArray[index], discountedCpm: discountCpm, impressions: each_impressions,
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay,
            isImpressionEdited: true
        }

        newArrayError[index] = {
            ...newArrayError[index], target: null, impressions: null, discountedOutlay: null,
            nonDiscountedOutlay: null
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
       // setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleTargetRegionChange = (event, index, i) => {
        //const newArray = [...forms];
        let group_label = event.target.selectedOptions[0].parentNode.label === "Targeting 1" ? "Targeting 1" :
                          event.target.selectedOptions[0].parentNode.label === "Targeting 2" ? "Targeting 2" : "ROS" ;
        console.log("group_label", group_label)
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];
        var targetVal = group_label === "Targeting 1" ? "TG1" : group_label === "Targeting 2" ? "TG2" : "ROS";
        // var targetRegionVal = event.target.value === "ROS" ? "" : event.target.value;
        var targetRegionVal = group_label === "ROS" ? "" : group_label;
        let section_data = newArray[index].adFormat === "PreRoll" ? preRollData :
            newArray[index].adFormat === "PreRoll Highlights" ? preRollHighlightData :
                newArray[index].adFormat === "PreRoll CTV" ? preRollCtvData :
                    newArray[index].adFormat === "MidRoll Live - PPL" ? midrollLiveData :
                        newArray[index].adFormat === "MidRoll CTV" ? midrollCTVData :
                            newArray[index].adFormat === "MidRoll Highlights" ? midrollHighlightsData :
                                newArray[index].adFormat === "Branded Cards" ? brandedCardData :
                                    newArray[index].adFormat === "Masthead" ? mastheadData :
                                        newArray[index].adFormat === "Frame Ads" ? frameAdsData :
                                            newArray[index].adFormat === "Fence Ads" ? fenceAdsData :
                                                newArray[index].adFormat === "Social Banner" ? socialBannerData : squeezeUpData;
        let sec = newArray[index].editLength;
        let selectedTime = sec === "15 Sec" ? 15 : sec === "20 Sec" ? 20 : sec === "25 Sec" ? 25 : sec === "30 Sec" ? 30 : sec === "0 Sec" ? 0 : 10;
        let ros_cost = Number(selectedTime / 10) * Number(section_data?.ros?.cost);
        let tg_cost = Number(selectedTime / 10) * Number(section_data?.targeted?.cost);
        let tg2_cost = Number(selectedTime / 10) * Number(section_data?.targeted2?.cost);
        let ros_cost_10 = Number(10 / 10) * Number(section_data?.ros?.cost);
        let tg_cost_10 = Number(10 / 10) * Number(section_data?.targeted?.cost);
        let tg2_cost_10 = Number(10 / 10) * Number(section_data?.targeted2?.cost);

        newArray[index] = {
            ...newArray[index], target: targetVal,
           // showTargetRegion: targetVal == "TG" ? true : false,
            targetRegion: (targetVal == "TG1" || targetVal == "TG2") ? event.target.value : "",
            rateCardCpm: targetVal == "TG1" ? tg_cost : targetVal == "TG2" ? tg2_cost : ros_cost,
            rateCardCpm10: newArray[index].adFormat === "Branded Cards" ? " - " : newArray[index].adFormat === " - " ? "Super 4's" : targetVal == "TG1" ? tg_cost_10 : targetVal == "TG2" ? tg2_cost_10 : ros_cost_10
        }
        newArrayError[index] = { ...newArrayError[index], targetRegion: null }

        let rateCard_cpm = newArray[index].rateCardCpm;
        let discountAmount = (rateCard_cpm / 100) * newArray[index].discount;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        //let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);

        newArray[index] = {
            ...newArray[index], discountedCpm: discountCpm, impressions: each_impressions,
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay,
            isImpressionEdited: true
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
       // setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleDurationChange = (event, index, i) => {
       // const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];
        var targetVal = event.target.value;
        newArray[index] = {
            ...newArray[index], duration: targetVal,
            showDateInput: targetVal == "full_tournament" ? false : true,
            customDuration: targetVal == "custom" ? newArray[index].customDuration : "" 
        }
        newArrayError[index] = { ...newArrayError[index], duration: null }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
       // setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleCustomDurationChange= (event, index, i) => {
         const newArray = [...allForms[i].forms];
         const newArrayError = [...formsError];
         newArray[index] = {
             ...newArray[index],
             customDuration: event.target.value 
         }
 
         let dataForms = [...allForms]
         let objIndex = dataForms.findIndex((obj => obj.id == i));
         dataForms[objIndex].forms = newArray
         setAllForms(dataForms);
         setFormsError(newArrayError);
     }

    const handleReachChange = (event, index, i) => {
         const newArray = [...allForms[i].forms];
         const newArrayError = [...formsError];
         var reachVal = event.target.value;
         newArray[index] = {...newArray[index], reach: reachVal}
 
         let dataForms = [...allForms]
         let objIndex = dataForms.findIndex((obj => obj.id == i));
         dataForms[objIndex].forms = newArray
         setAllForms(dataForms);
         setFormsError(newArrayError);
     }

    const handleDateChange = (event, index, date, i) => {
       // const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

       // console.log(event.target.value, index, date)
        if (date === "startDate") {
            newArray[index] = { ...newArray[index], startDate: event.target.value }
            newArrayError[index] = { ...newArrayError[index], startDate: null }
        }
        else {
            newArray[index] = { ...newArray[index], endDate: event.target.value }
            newArrayError[index] = { ...newArrayError[index], endDate: null }
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
        //setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleRateCardCpmChange = (event, index, i) => {
        const onlyNums = Number(event.target.value.replace(/[^0-9]/g, ''));
       // const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

        let rateCard_cpm = onlyNums;
        let discount = newArray[index].discount;
        let discountAmount = (rateCard_cpm / 100) * discount;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        // let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        // console.log("rateCard_cpm",rateCard_cpm,"each_split",each_split, "each_outlay",each_outlay)
        newArray[index] = {
            ...newArray[index], rateCardCpm: onlyNums, discount: discount, discountedCpm: discountCpm, impressions: each_impressions,
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay, isImpressionEdited: true
        }
        newArrayError[index] = {
            ...newArrayError[index], discount: null, discountedCpm: null, impressions: null, discountedOutlay: null,
            nonDiscountedOutlay: null
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
       //setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleDiscountChange = (event, index, i) => {
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        let onlyNums;
        if (rx_live.test(event.target.value))
       { onlyNums= event.target.value;
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

        let rateCard_cpm = newArray[index].rateCardCpm;
        let discountAmount = (rateCard_cpm / 100) * onlyNums;
        let discountCpm = Number(rateCard_cpm - discountAmount).toFixed(2);
        let each_split = Math.round(newArray[index].split);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        // let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);

        newArray[index] = {
            ...newArray[index], discount: onlyNums, discountedCpm: discountCpm, impressions: each_impressions,
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay, isImpressionEdited: true
        }
        newArrayError[index] = {
            ...newArrayError[index], discount: null, discountedCpm: null, impressions: null, discountedOutlay: null,
            nonDiscountedOutlay: null
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
        //setForms(newArray);
        setFormsError(newArrayError);
 }
       // const onlyNums = Number(event.target.value.replace(/[^0-9]/g, ''));
        //const newArray = [...forms];
       
        // setIsEditStarts(true);
    }

    const handleImpressionsChange = (event, index, i) => {
        const onlyNums = Number(event.target.value.replace(/[^0-9]/g, ''));
        const alphaNum = event.target.value.replace(/[^A-Za-z0-9-_/&!|() ]/g, '')
       // const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

        let rateCard_cpm = newArray[index].rateCardCpm;
        let each_outlay = newArray[index].outlay;
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? alphaNum : onlyNums;
        let discountCpm = newArray[index].discountedCpm;
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        let each_split = (discounted_outlay/allForms[i].outlay) * 100;

        newArray[index] = {
            ...newArray[index], impressions: each_impressions,  split: Math.round(each_split),
            discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay, isImpressionEdited: true
        }
        newArrayError[index] = {
            ...newArrayError[index], discountedCpm: null, impressions: null, discountedOutlay: null,
            nonDiscountedOutlay: null
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
        //setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleDiscountedOutlayChange = (event, index, i) => {
        const onlyNums = Number(event.target.value.replace(/[^0-9]/g, ''));
        //const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

        let rateCard_cpm = newArray[index].rateCardCpm;
        let each_outlay = newArray[index].outlay;
        let discounted_outlay = onlyNums;
        let discountCpm = newArray[index].discountedCpm;
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(onlyNums, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        let each_split = (onlyNums/allForms[i].outlay) * 100;

        newArray[index] = {
            ...newArray[index], impressions: each_impressions, split: Math.round(each_split),
            discountedOutlay: onlyNums, nonDiscountedOutlay: non_discounted_outlay, isImpressionEdited: true
        }
        newArrayError[index] = {
            ...newArrayError[index], discountedCpm: null, impressions: null, discountedOutlay: null,
            nonDiscountedOutlay: null
        }

        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray
        setAllForms(dataForms);
        //setForms(newArray);
        setFormsError(newArrayError);
        // setIsEditStarts(true);
    }

    const handleSplitChange = (event, index, i) => {
        const onlyNums = Number(event.target.value.replace(/[^0-9]/g, ''));
        // let data;
        // if (!isSplitEdited) {
        //     setIsSplitEdited(true);
        //     data = forms.map(x => { return ({ ...x, outlay: 0, split: 0, impressions: 0, discountedOutlay: 0, nonDiscountedOutlay: 0 }) });
        // }
        
        // const newArray = !isSplitEdited ? [...data] : [...forms];
       // const newArray = [...forms];
        const newArray = [...allForms[i].forms];
        const newArrayError = [...formsError];

        let rateCard_cpm = newArray[index].rateCardCpm;
        // let discountAmount = (rateCard_cpm/100) * onlyNums;
        let discountCpm = newArray[index].discountedCpm;
        let each_split = Math.round(onlyNums);
        let each_outlay = Number(allForms[i].outlay * (each_split / 100));
        // let each_impressions = Number(each_outlay * 1000 / discountCpm).toFixed(2);
        let each_impressions = newArray[index].adFormat === "Squeeze Up" ? newArray[index].impressions : getEachImpressions(each_outlay, discountCpm);
        let discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? rateCard_cpm : getEachDiscountedoutlay(each_impressions, discountCpm);
        let non_discounted_outlay = newArray[index].adFormat === "Squeeze Up" ? discountCpm : getEachNonDiscountedoutlay(each_impressions, rateCard_cpm);
        //console.log("rateCard_cpm",rateCard_cpm,"discountCpm",discountCpm, "non_discounted_outlay",non_discounted_outlay)
        newArray[index] = {
            ...newArray[index], split: Math.round(onlyNums), outlay: Number(allForms[i].outlay * (onlyNums / 100)), isSplitEdited: true,
            // discountedCpm: discountCpm, 
            impressions: each_impressions, discountedOutlay: discounted_outlay, nonDiscountedOutlay: non_discounted_outlay, isImpressionEdited: true
        }
        newArrayError[index] = { ...newArrayError[index], split: null, impressions: null, discountedOutlay: null, nonDiscountedOutlay: null }
        // if (newArray.map(x => x.split).reduce((partialSum, a) => (Number(partialSum) + Number(a)).toFixed(2)) <= 100) {
           
            let dataForms = [...allForms]
            let objIndex = dataForms.findIndex((obj => obj.id == i));
            dataForms[objIndex].forms = newArray
            setAllForms(dataForms);
            // setForms(newArray);
            setFormsError(newArrayError);
        // }
        // else {
        //     alert("You can't enter more than 100%")
        // }
        // setIsEditStarts(true);
    }

    const handleTournamentChange = (value) => {
        let newArray = [...forms];
        let newArrayError = [...formsError];

        let selectedItem = filteredSportsSeriesList.filter(item => item.series_name === value)[0]
        let start_date = selectedItem.start_date;
        let end_date = selectedItem.end_date;
        setBrandDetails({ ...brandDetails, tournamentId: selectedItem.id, tournamentName: value})

        newArray = newArray.map(x => { return ({ ...x, startDate: start_date, endDate: end_date }) })

        setForms(newArray);
        setFormsError(newArrayError);
        setStartDate(start_date);
        setEndDate(end_date);
        setAllForms(allForms.map(x=> {return ({...x, forms: x.forms.map(y=> {return ({...y, startDate: start_date, endDate: end_date})})})}))
       // console.log("allForms",datas)
    }

    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event, index, i) => {
        setAnchorEl1(event.currentTarget);
        setCurrentFormIndex(i);
        setCurrentIndex(index);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleOutlay = (e, i) => {
        const newArray = [...allForms[i].forms];
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setBrandDetails({ ...brandDetails, [e.target.name]: Number(onlyNums) });

        let newForm = forms.map(x => {
            return ({
                ...x, 
                outlay: getEachOutlay(onlyNums, x.split),
                impressions: getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm),
                discountedOutlay: getEachDiscountedoutlay(getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm), x.discountedCpm),
                nonDiscountedOutlay: getEachNonDiscountedoutlay(getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm), x.rateCardCpm)
            })
        })
        setForms(newForm);
        setFormsError([...formsError, {
            id: forms.length, editLength: null, target: null, targetRegion: null, duration: null,
            startDate: null, endDate: null, rateCardCpm: null, discount: null, discountedCpm: null, nonDiscountedOutlay: null,
            impressions: null
        }])
        setEachOutlay(Number(brandDetails.outlay / (forms.length + 1)).toFixed(2));
        setIsSplitEdited(false);
    }

    const handleEachOutlay = (e, i) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if(i === 0){
        setBrandDetails({ ...brandDetails, [e.target.name]: Number(onlyNums) });
        }
       const newArray = [...allForms[i].forms];
       let newForm = newArray.map(x => {
        return ({
            ...x, 
            outlay: getEachOutlay(onlyNums, x.split),
            impressions: getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm),
            discountedOutlay: getEachDiscountedoutlay(getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm), x.discountedCpm),
            nonDiscountedOutlay: getEachNonDiscountedoutlay(getEachImpressions(getEachOutlay(onlyNums, x.split), x.discountedCpm), x.rateCardCpm)
        })
    })
        let dataForms = [...allForms]
            let objIndex = dataForms.findIndex((obj => obj.id == i));
            dataForms[objIndex].forms = newForm
            dataForms[objIndex].outlay = Number(onlyNums)
            setAllForms(dataForms);
       // setForms(newForm);
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    function formatDate1(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${ye}-${mo}-${da}`
        return newDate;
    }

    const formatNumbers = (num, val) => {
        let numbers;
        let char;
        if (num < 1000) { numbers = num; char = '' }
        else if (num >= 1000 && num < 100000) { numbers = (num / 1000); char = ' K' }
        else if (num >= 100000 && num < 10000000) { numbers = (num / 100000); char = ' L' }
        else if (num >= 10000000) { numbers = (num / 10000000); char = ' CR' }
        return numbers.toFixed(1) + char
    }
    

    const onFormClone = (i) => {
        let formToClone = allForms[i];
       // console.log("allForms.length",allForms.length)
        let dataForms = [...allForms,
                            {id: allForms.length,
                             outlay: formToClone.outlay,
                             forms: formToClone.forms,
                             expandDateCol: formToClone.expandDateCol, 
                             middrollLiveList: formToClone.middrollLiveList,
                             middrollCTVList: formToClone.middrollCTVList,
                             middrollHighlightsList: formToClone.middrollHighlightsList,
                             squeezeList: formToClone.squeezeList,
                             isSplitEdited: formToClone.isSplitEdited,
                             preRollData: formToClone.preRollData,
                             preRollHighlightData: formToClone.preRollHighlightData,
                             preRollCtvData: formToClone.preRollCtvData,
                             mastHeadData: formToClone.mastHeadData,
                             frameAdsData: formToClone.frameAdsData,
                             fenceAdsData: formToClone.fenceAdsData,
                             socialBannerData: formToClone.socialBannerData,
                             midrollLiveData: formToClone.midrollLiveData,
                             midrollCTVData: formToClone.midrollCTVData,
                             midrollHighlightsData: formToClone.midrollHighlightsData,
                             brandedCardData: formToClone.brandedCardData,
                             squeezeUpData: formToClone.squeezeUpData,
                            //  optionPackageName: formToClone.optionPackageName,
                            //  optionHeading: `Option ${Number(allForms.length+1)}`
                            }]
        //let objIndex = dataForms.findIndex((obj => obj.id == i));
       // dataForms[objIndex].forms = newArray
        setAllForms(dataForms); 
    }

    const handlePackageName = (event, i) => {
        const newArray = [...allForms[i].forms];
        let newArray1 = newArray.map(x => { return ({ ...x, optionPackageName: event.currentTarget.textContent }) })
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray1
        setAllForms(dataForms);
        // console.log("dataForms",dataForms)
    }

    const handleOptionHeading = (event, i) => {
        const newArray = [...allForms[i].forms];
        let newArray1 = newArray.map(x => { return ({ ...x, optionHeading: event.currentTarget.textContent }) })
        let dataForms = [...allForms]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].forms = newArray1
        setAllForms(dataForms);
        // console.log("dataForms",dataForms)
    }

    const handleNeatGrid = (e) => {
       // console.log("name",e.target.name, "value",typeof(e.target.value))
        let value = e.target.value === "false" ? true : false
        setNeatGrid({ ...neatGrid, [e.target.name]: value });
    }

    const onEachFormDelete = (index) => {
        let newForm = allForms.filter(item => item.id !== index);
     
        let oneMore = newForm.map((x, index) => {
            return ({...x, id: index})
        })
        setAllForms(oneMore);
    }

    function fnExcelReport() {
        setShowExcel(true);
        setTimeout(
            () => setShowExcel(false), 
            2000
          );
      }

      function fnExcelReportNeat() {
        setShowExcelNeat(true);
        setOpenNeatDialog(false);
        setTimeout(
            () => setShowExcelNeat(false), 
            2000
          );
      }

    function numberFormatUS(x) {
        let nf = new Intl.NumberFormat('en-US');
        return nf.format(x);
    }

    function numberFormatIN(x) {
        let nf = new Intl.NumberFormat('en-IN');
        return nf.format(x);
    }

    const handleNeatClickOpen = () => {
        setOpenNeatDialog(true);
    };

    const handleNeatClose = () => {
        setOpenNeatDialog(false);
    };

    const handlePreviewClickOpen = () => {
        setOpenPreviewDialog(true);
    };

    const handlePreviewClose = () => {
        setOpenPreviewDialog(false);
    };
   
  console.log("allForms",allForms)   

    return (
        <>
        <DashboardLayout>
            {/* <MediaPlanExcelPreview allForms={allForms} brandDetails={brandDetails} /> */}
            {showExcel && (
                <MediaPlanExcelSheet allForms={allForms} brandDetails={brandDetails} fileName={saveForm.fileName}/>
            )}
            {showExcelP && (
                <MediaPlanExcelPreview allForms={allForms} brandDetails={brandDetails} fileName={saveForm.fileName}/>
            )}
            {showExcelNeat && (
                <MediaPlanExcelNeat allForms={allForms} brandDetails={brandDetails} neatGrid={neatGrid} fileName={saveForm.fileName}/>
            )}
            <DashboardNavbar absolute={false} light={false} isMini={false} isHideSideNav={true} />
            <Card>
                <Box px={3} pb={3} xs={{ width: '100%', padding: "10px" }}>
                    <CardContent className="px-0">
                        <Typography variant="h4" component="div" className="primary-text">
                            Media Plan Sheet
                        </Typography>

                        <Box className="box-container">

                            <Box component="form" noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={4} >
                                        <label className="text-sm">Brand Name</label>
                                        {/* <TextField
                                            className="form-control"
                                            id="outlined-basic"
                                            label="Brand Name"
                                            variant="outlined"
                                            name="brandName"
                                        /> */}
                                        <Autocomplete
                                            disableClearable
                                            value={brandDetails.brandName}
                                            options={brandList}
                                            onChange={(event, newValue) => {
                                                setBrandDetails({ ...brandDetails, brandName: newValue, brandId: filteredBrandList.filter(x=> x.brand_name === newValue)[0].id });
                                                setSaveForm({ ...saveForm, brandName: newValue, fileName: `${newValue + "_" + saveForm.tournamentName}`,
                                                            region: filteredBrandList.filter(x=> x.brand_name === newValue)[0].region,
                                                            itwSalesPoc: filteredBrandList.filter(x=> x.brand_name === newValue)[0]?.itw_contact_name,
                                                            digitalPoc: filteredBrandList.filter(x=> x.brand_name === newValue)[0]?.digital_io_contact_name });
                                                setErrorDetails({ ...errorDetails, brandName: null });
                                            }}
                                            onFocus={() => getBrandList()}
                                            size="small"
                                            // sx={{ width: "5rem" }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    className="form-control"
                                                    id="outlined-basic"
                                                    label="Brand Name"
                                                    variant="outlined"
                                                    name="brandName"
                                                    onChange={(e) => getBrandList(e.target.value)}
                                                    value={brandDetails.brandName}
                                                    error={errorDetails.brandName}
                                                    helperText={errorDetails.brandName}
                                                />
                                            }
                                        />
                                    </Col>
                                    {/* <Col item xs={12} md={4}  >
                                        <label className="text-sm">Outlay</label>
                                        <TextField
                                            className="form-control custom-input"
                                            id="outlined-basic"
                                            label="Outlay"
                                            variant="outlined"
                                            name="outlay"
                                            onChange={(e)=> handleEachOutlay(e, 0)}
                                            value={brandDetails.outlay}
                                            error={errorDetails.outlay}
                                            helperText={errorDetails.outlay}
                                        />
                                    </Col> */}
                                    <Col item xs={12} md={4} >
                                        <label className="text-sm">Tournament Name</label>
                                        <Autocomplete
                                            disableClearable
                                            value={brandDetails.tournamentName}
                                            options={sportsSeriesList}
                                            onChange={(event, newValue) => {
                                                console.log("Tournament",newValue)
                                                setBrandDetails({ ...brandDetails, tournamentName: newValue });
                                                setSaveForm({ ...saveForm, tournamentName: newValue, fileName: `${saveForm.brandName + "_" + newValue}` });
                                                setErrorDetails({ ...errorDetails, tournamentName: null });
                                                handleTournamentChange(newValue)
                                            }}
                                            onFocus={() => getSportsSeries()}
                                            size="small"
                                            // sx={{ width: "5rem" }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    className="form-control"
                                                    id="outlined-basic"
                                                    label="Select Tournament"
                                                    variant="outlined"
                                                    name="tournamentName"
                                                    onChange={(e) => getSportsSeries(e.target.value)}
                                                    value={brandDetails.tournamentName}
                                                    error={errorDetails.tournamentName}
                                                    helperText={errorDetails.tournamentName}
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </CardContent>
                    
                    {/* {(brandDetails.brandName !== null && brandDetails.tournamentName !== null) && ( */}
                        <Grid container spacing={6}>
                            <Grid item xs={12} className="mt-4 pt-0">
                            {allForms?.map((x,i)=> 
                                <Card>
                                    <Row className="pt-2 pb-3 px-3">

                                        <Col item lg={7} md={8} sm={12} xs={{ span: 12, order: 2 }}>
                                        <label className="text-sm">Add line Item </label>
                                        <div className="lineitembtns">
                                            <div className="ck-button">
                                                <label>
                                                    <Button
                                                    // disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                        onClick={() => getPrerollDetails(i)}><span>PreRoll</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getPrerollHighlightDetails(i)}><span>PreRoll Highlights</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getPrerollCtvDetails(i)}><span>PreRoll CTV</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getMastheadDetails(i)}><span>Masthead</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getFrameAdsDetails(i)}><span>Frame Ads</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getFenceAdsDetails(i)}><span>Fence Ads</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button onClick={() => getSocialBannerDetails(i)}><span>Social Banner</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                
                                                    <Button 
                                                    //disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                            onClick={() => handleMidrollLiveListShow(i)}><span>MidRoll Live - PPL</span></Button>
                                                
                                                    {/* {console.log(middrollLiveList)} */}
                                                    
                                                        {x.middrollLiveList && (
                                                            <ClickAwayListener onClickAway={() => closeMenu(i)}>
                                                            <div  className="ck-button-list">
                                                                <ul>
                                                                    <li><Button onClick={() => getMidrollLiveDetails("10 Sec", i)}>MidRoll Live - PPL - 10Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollLiveDetails("15 Sec", i)}>MidRoll Live - PPL - 15Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollLiveDetails("20 Sec", i)}>MidRoll Live - PPL - 20Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollLiveDetails("25 Sec", i)}>MidRoll Live - PPL - 25Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollLiveDetails("30 Sec", i)}>MidRoll Live - PPL - 30Sec</Button></li>
                                                                </ul>
                                                            </div>
                                                            </ClickAwayListener>
                                                        )}
                                                    
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button 
                                                    //disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                            onClick={() =>  handleMidrollCTVListShow(i)}><span>MidRoll CTV</span></Button>
                                                        {x.middrollCTVList ? (
                                                            <ClickAwayListener onClickAway={() => closeMenu(i)}>
                                                            <div className="ck-button-list">
                                                                <ul>
                                                                    <li><Button onClick={() => getMidrollCtvDetails("10 Sec", i)}>MidRoll CTV - 10Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollCtvDetails("15 Sec", i)}>MidRoll CTV - 15Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollCtvDetails("20 Sec", i)}>MidRoll CTV - 20Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollCtvDetails("25 Sec", i)}>MidRoll CTV - 25Sec</Button></li>
                                                                    <li><Button onClick={() => getMidrollCtvDetails("30 Sec", i)}>MidRoll CTV - 30Sec</Button></li>
                                                                </ul>
                                                            </div>
                                                            </ClickAwayListener>
                                                        ): null}
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button 
                                                    //disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                            onClick={() => handleMidrollHighlightsListShow(i)}><span>MidRoll Highlights</span></Button>
                                                    {x.middrollHighlightsList ? (
                                                        <ClickAwayListener onClickAway={() => closeMenu(i)}>
                                                        <div className="ck-button-list">
                                                            <ul>
                                                                <li><Button onClick={() => getMidrollHighlightsDetails("10 Sec", i)}>MidRoll Highlights - 10Sec</Button></li>
                                                                <li><Button onClick={() => getMidrollHighlightsDetails("15 Sec", i)}>MidRoll Highlights - 15Sec</Button></li>
                                                                <li><Button onClick={() => getMidrollHighlightsDetails("20 Sec", i)}>MidRoll Highlights - 20Sec</Button></li>
                                                                <li><Button onClick={() => getMidrollHighlightsDetails("25 Sec", i)}>MidRoll Highlights - 25Sec</Button></li>
                                                                <li><Button onClick={() => getMidrollHighlightsDetails("30 Sec", i)}>MidRoll Highlights - 30Sec</Button></li>
                                                            </ul>
                                                        </div>
                                                        </ClickAwayListener>
                                                    ): null}
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button 
                                                    //disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                        onClick={() => getBrandedCardDetails(i)}><span>Branded Cards</span></Button>
                                                </label>
                                            </div>
                                            <div className="ck-button">
                                                <label>
                                                    <Button 
                                                    //disabled={(brandDetails.tournamentName === null || brandDetails.outlay === null) ? true : false}
                                                            onClick={() => handleSqueezeListShow(i)}><span>Squeeze Up</span></Button>
                                                    {x.squeezeList && (
                                                        <ClickAwayListener onClickAway={() => closeMenu(i)}>
                                                        <div className="ck-button-list">
                                                            <ul>
                                                                <li><Button onClick={() => getSqueezeUpDetails("0 Sec", "Super 4's", i)}>Super 4's</Button></li>
                                                                <li><Button onClick={() => getSqueezeUpDetails("0 Sec", "Super 6's", i)}>Super 6's</Button></li>
                                                                <li><Button onClick={() => getSqueezeUpDetails("0 Sec", "Milestone", i)}>Milestone</Button></li>
                                                                <li><Button onClick={() => getSqueezeUpDetails("0 Sec", "Custom", i)}>Custom</Button></li>
                                                            </ul>
                                                        </div>
                                                        </ClickAwayListener>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                   
                                    <Col item lg={5} md={4} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 1 }} >
                                            <label className="text-sm">Outlay</label>
                                            <TextField
                                                className="form-control custom-input"
                                                id="outlined-basic"
                                                label="Outlay"
                                                variant="outlined"
                                                name="outlay"
                                                onChange={(e)=> handleEachOutlay(e, i)}
                                                value={x.outlay}
                                                autoComplete="off"
                                            // error={errorDetails.outlay}
                                            //  helperText={errorDetails.outlay}
                                            />
                                        </Col>
                                       
                                    </Row>
                                    {x?.forms.length > 0 && (
                                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <MDBox
                                            className="accordion-items"
                                            variant="gradient"
                                            bgColor="info"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                        >
                                            <MDTypography variant="h6" color="white">
                                                
                                                    <span
                                                    contentEditable="true"
                                                    onBlur={(e)=> handlePackageName(e, i)}
                                                    >{x?.forms[0].optionPackageName}</span>
                                                        {/* <div className="mt-2">
                                                            <TextField
                                                                className="discount-input-table input-width-75"
                                                                type="text"
                                                                onChange={(e) => handlePackageName(e, i)}
                                                                value={x?.forms[0].optionPackageName}
                                                                name="customDuration" />
                                                        </div> */}
                                                 - {brandDetails.tournamentName !== null ? brandDetails.tournamentName : ""} | {x?.outlay !== null ? formatNumbers(x?.outlay) : 0} | 
                                                 <span 
                                                    contentEditable="true"
                                                    onBlur={(e) => handleOptionHeading(e, i)}
                                                    > {x?.forms[0].optionHeading} </span> {i+1}
                                                 {/* <div className="mt-2">
                                                            <TextField
                                                                className="discount-input-table input-width-75"
                                                                type="text"
                                                                onChange={(e) => handleOptionHeading(e, i)}
                                                                value={x?.forms[0].optionHeading}
                                                                name="customDuration" />
                                                        </div> */}
                                                
                                                <span className="table-heading-btn"><Button className="clone-btn ml-3" onClick={()=>onFormClone(i)}><span className="clone-icon"><FileCopyIcon /></span> <span className="clone-text">Clone</span></Button>&nbsp;&nbsp;
                                                {x.id !== 0 && <Button className="clone-btn delete-btn mr-2" onClick={()=>onEachFormDelete(i)}><span className="delete-icon"><DeleteIcon /></span> <span className="delete-text">Delete</span></Button>}
                                                <Accordion.Header 
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-label="Expand"
                                                    aria-controls={`additional-actions${i}-content`}
                                                    id="additional-actions1-header"
                                                    >
                                                </Accordion.Header>
                                                </span>
                                            </MDTypography>
                                        </MDBox>
                                        <Accordion.Body>
                                            <Box className="table-container">
                                            <table className="table-default">
                                            <thead>
                                                <tr>
                                                    <th><div className="td-content"></div></th>
                                                    <th><div className="td-content">Ad Format</div></th>
                                                    <th><div className="td-content">Edit length</div></th>
                                                    <th><div className="td-content">Targeting</div></th>
                                                    <th><div className="td-content">Duration &nbsp;&nbsp;<a onClick={()=> handleExpandDate(i)} className="btn-xs">{x.expandDateCol ? "- Hide" : "+ Expand"}</a></div></th>
                                                    {x.expandDateCol && (
                                                        <>
                                                            <th><div className="td-content">Start Date</div></th>
                                                            <th><div className="td-content">End Date</div></th>
                                                        </>
                                                    )}
                                                    <th><div className="td-content">Rate Card CPM</div></th>
                                                    <th><div className="td-content">Discount % <a onClick={()=> handleFillAllDiscounts(i)} className="btn-xs"> Fill</a></div></th>
                                                    <th><div className="td-content">Discounted CPM</div></th>
                                                    <th><div className="td-content">Impressions</div></th>
                                                    <th><div className="td-content">Discounted Outlay</div></th>
                                                    <th><div className="td-content">Split %</div></th>
                                                    <th><div className="td-content">Non Discounted Outlay</div></th>
                                                    <th><div className="td-content">Reach</div></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {x?.forms?.map((item, index) =>
                                                    <tr>
                                                        <td>
                                                            <div className="td-content">
                                                                <a
                                                                    className="three-dots-menu"
                                                                    id="fade-button"
                                                                    aria-controls={open1 ? 'fade-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open1 ? 'true' : undefined}
                                                                    onClick={(e) => handleClick1(e, index, i)}
                                                                >
                                                                    <MoreVertIcon />
                                                                </a>
                                                                <Menu
                                                                    id="fade-menu"
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'fade-button',
                                                                    }}
                                                                    anchorEl={anchorEl1}
                                                                    open={open1}
                                                                    onClose={handleClose1}
                                                                    TransitionComponent={Fade}
                                                                    key={index}
                                                                >
                                                                    <MenuItem onClick={() => onFormReset(currentIndex, currentFormIndex)}><span className="text-primary">Reset</span></MenuItem>
                                                                    <MenuItem onClick={() => onFormDelete(currentIndex, currentFormIndex)}><span className="text-danger">Delete</span></MenuItem>
                                                                </Menu>
                                                            </div>
                                                        </td>

                                                        <td><div className="td-content">{item?.adFormat}</div></td>
                                                        <td>
                                                            <div className="td-content">
                                                                <select className="table-select"
                                                                    onChange={(e) => handleInputChange(e, item.id, i)}
                                                                    value={item.editLength}
                                                                    disabled={(item.adFormat === "PreRoll" || item.adFormat === "PreRoll Highlights" || item.adFormat === "PreRoll CTV" ||
                                                                    item.adFormat === "Masthead" || item.adFormat === "Frame Ads" || item.adFormat === "Fence Ads" || item.adFormat === "Social Banner" || item.adFormat === "Branded Cards") ? true : false}>
                                                                    {item.adFormat === "Branded Cards" && <option value={"NA"}>NA</option>}
                                                                    {item.adFormat === "Squeeze Up" &&
                                                                        <>
                                                                            <option value={"Super 4's"}>Super 4's</option>
                                                                            <option value={"Super 6's"}>Super 6's</option>
                                                                            <option value={"Milestone"}>Milestone</option>
                                                                            <option value={"Custom"}>Custom</option>
                                                                        </>}
                                                                    {item.adFormat !== "Squeeze Up" &&
                                                                        <>
                                                                            <option value={"10 Sec"}>10 Sec</option>
                                                                            <option value={"15 Sec"}>15 Sec</option>
                                                                            <option value={"20 Sec"}>20 Sec</option>
                                                                            <option value={"25 Sec"}>25 Sec</option>
                                                                            <option value={"30 Sec"}>30 Sec</option>
                                                                        </>}
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="td-content">
                                                                {/* <select
                                                                    className="table-select"
                                                                    onChange={(e) => handleTargetChange(e, item.id, i)}
                                                                    value={item.target}
                                                                    name="target"
                                                                >
                                                                    <option value="ROS">ROS</option>
                                                                    <option value="TG">TG</option>
                                                                </select> */}
                                                                {/* {item.showTargetRegion && ( */}
                                                                    <div>
                                                                        <select
                                                                            labelId="demo-simple-select-autowidth-label"
                                                                            id="demo-simple-select-autowidth"
                                                                            className="table-select"
                                                                            onChange={(e) => handleTargetRegionChange(e, item.id, i)}
                                                                            value={item.targetRegion}
                                                                            name="targetRegion"
                                                                            autoWidth
                                                                        >
                                                                            <option value="ROS">ROS</option>
                                                                            <optgroup label="Targeting 1">
                                                                                <option value="HSM">HSM</option>
                                                                                <option value="Top 6 metros">Top 6 metros</option>
                                                                                <option value="Top 10 metros">Top 10 metros</option>
                                                                                <option value="1 Mn + Towns">1 Mn + Towns</option>
                                                                                <option value="Southern States">Southern States</option>
                                                                                <option value="West India">West India</option>
                                                                                <option value="East India and NE">East India and NE</option>
                                                                                <option value="Andhra Pradesh + Telangana">Andhra Pradesh + Telangana</option>
                                                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                                                <option value="25K+ Devices">25K+ Devices</option>
                                                                                <option value="15K+ Devices">15K+ Devices</option>
                                                                                <option value="<15K Devices">&lt; 15K Devices</option>
                                                                                <option value="25 Yrs+">25 Yrs+</option>
                                                                                <option value="<25 Yrs">&lt; 25 Yrs</option>
                                                                            </optgroup>
                                                                            <optgroup label="Targeting 2">
                                                                                <option value="HSM plus 15K + Devices">HSM plus 15K + Devices</option>
                                                                                <option value="Top 6 metros plus 15K + Devices">Top 6 metros plus 15K + Devices</option>
                                                                                <option value="Southern States plus 15K + Devices">Southern States plus 15K + Devices</option>
                                                                                <option value="25 Yrs+  plus 15K+ Devices">25 Yrs+  plus 15K+ Devices</option>
                                                                                <option value="<25 Yrs  plus 15K+ Devices">&lt;25 Yrs  plus 15K+ Devices</option>
                                                                            </optgroup>
                                                                        </select>
                                                                    </div>
                                                                {/* )} */}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="td-content">
                                                                <select
                                                                    //  onChange={selectDuration}
                                                                    className="table-select"
                                                                    onChange={(e) => handleDurationChange(e, item.id, i)}
                                                                    value={item.duration}
                                                                    name="duration">
                                                                    <option value="full_tournament">Full Tournament</option>
                                                                    {/* <option value="ODI_only">ODI Only</option>
                                                                    <option value="T20_only">T20 Only</option>
                                                                    <option value="Test_only">Test Only</option> */}
                                                                    <option value="custom">Custom</option>
                                                                </select>
                                                                {item.duration === "custom" && (
                                                                     <div className="mt-2">
                                                                    <TextField
                                                                     style={{width: "100%"}}
                                                                    className="discount-input-table input-width-75"
                                                                    type="text"
                                                                    onChange={(e) => handleCustomDurationChange(e, item.id, i)}
                                                                    value={item.customDuration}
                                                                    name="customDuration"
                                                                    autoComplete="off"
                                                                    />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                        {x.expandDateCol && (
                                                            item.showDateInput ? (
                                                                <>
                                                                    <td><div className="td-content"><TextField value={item?.startDate ? formatDate1(item.startDate) : formatDate1(new Date())} placeholder="start date" type="date" onChange={(e) => handleDateChange(e, item.id, "startDate", i)} autoComplete="off" /></div></td>
                                                                    <td><div className="td-content"><TextField value={item?.endDate ? formatDate1(item.endDate) : formatDate1(new Date())} placeholder="end date" type="date" onChange={(e) => handleDateChange(e, item.id, "endDate", i)} autoComplete="off" /></div></td>
                                                                </>
                                                            ) :
                                                                <>
                                                                    <td><div className="td-content">{item?.startDate ? formatDate(item.startDate) : formatDate(new Date())}</div></td>
                                                                    <td><div className="td-content">{item?.endDate ? formatDate(item.endDate) : formatDate(new Date())}</div></td>
                                                                </>

                                                        )}
                                                        <td><div className="td-content">
                                                            <TextField
                                                                className="discount-input-table"
                                                                type="text"
                                                                onChange={(e) => handleRateCardCpmChange(e, item.id, i)}
                                                                // inputProps={{ maxLength: 2 }}
                                                                value={item.rateCardCpm}
                                                                name="rateCardCpm"
                                                                autoComplete="off"
                                                                />
                                                        </div></td>
                                                        <td><div className="td-content">
                                                            <TextField
                                                                className="discount-input-table"
                                                                type="text"
                                                                onChange={(e) => handleDiscountChange(e, item.id, i)}
                                                                inputProps={{ maxLength: 5 }}
                                                                value={item.discount}
                                                                name="discount"
                                                                autoComplete="off"
                                                                />
                                                        </div></td>
                                                        <td><div className="td-content">{item.adFormat === "Squeeze Up" ? formatNumbers(Number(item.discountedCpm)) : item.discountedCpm}</div></td>
                                                        <td><div className="td-content">
                                                            <TextField
                                                                className="discount-input-table input-width-75"
                                                                type="text"
                                                                onChange={(e) => handleImpressionsChange(e, item.id, i)}
                                                                value={item.adFormat === "Squeeze Up" ? item.impressions : numberFormatUS(item.impressions)}
                                                                name="impressions"
                                                                autoComplete="off"
                                                                />
                                                        </div></td>
                                                        <td><div className="td-content"><span className="icon-table-span">&#8377;</span>
                                                            <TextField
                                                                className="discount-input-table input-width-75"
                                                                type="text"
                                                                onChange={(e) => handleDiscountedOutlayChange(e, item.id, i)}
                                                                value={numberFormatIN(item.discountedOutlay)}
                                                                name="discountedOutlay" 
                                                                autoComplete="off"
                                                                />
                                                        </div></td>
                                                        <td><div className="td-content">
                                                            <TextField
                                                                className="discount-input-table"
                                                                type="text"
                                                                onChange={(e) => handleSplitChange(e, item.id, i)}
                                                                inputProps={{ maxLength: 2 }}
                                                                value={item.split}
                                                                name="split" 
                                                                autoComplete="off"
                                                                />
                                                            <span className="icon-table-span">%</span></div></td>
                                                        <td><div className="td-content">&#8377; {numberFormatIN(item.nonDiscountedOutlay)}</div></td>
                                                        <td><div className="td-content">
                                                        <TextField
                                                                className="discount-input-table input-width-75"
                                                                type="text"
                                                                onChange={(e) => handleReachChange(e, item.id, i)}
                                                                value={item.reach}
                                                                name="impressions"
                                                                autoComplete="off"
                                                                />
                                                            </div></td>
                                                    </tr>
                                                 )} 
                                                <tr>
                                                    <td colSpan={x.expandDateCol ? "10" : "8"} align="center"><div className="td-content"><b>Grand Total</b></div></td>

                                                    <td><div className="td-content"><b>{x.forms?.length > 0 ? numberFormatUS(x.forms?.map(item => typeof(item?.impressions) === "string" ? 1 : item?.impressions).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></div></td>
                                                    <td><div className="td-content"><b>&#8377;{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.discountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></div></td>
                                                    <td><div className="td-content"><b>{x.forms?.length > 0 ? x.forms?.map(item => item?.split).reduce((partialSum, a) => Math.round((Number(partialSum) + Number(a)))) : 0} %</b></div></td>
                                                    <td><div className="td-content"><b>&#8377;{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.nonDiscountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></div></td>
                                                    <td><div className="td-content"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                            </Box>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Accordion>
                                    )}
                                    {isLoading &&
                                        <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                            <LinearProgress color="success" />
                                        </Stack>}
                                </Card>
                                )}
                            </Grid>
                            <Grid item md={12} className="footer-button">
                                <Box className="d-flex footer-buttons">
                                    <Button size="small" onClick={handleOpen}>Save New</Button>
                                    <Button size="small" onClick={fnExcelReport}><DownloadIcon /> Raw grid</Button>
                                    <Button size="small" onClick={handleNeatClickOpen}><DownloadIcon /> Neat Grid</Button>
                                    <Button size="small" onClick={handlePreviewClickOpen}>Preview</Button>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    {/* )} */}
                </Box>
            </Card>


            {/* Save new form */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box component="form" noValidate autoComplete="off" >
                        <Row>
                            <Col item xs={12} md={6} >
                                <label className="text-sm">Brand Name</label>
                                <TextField
                                    className="form-control"
                                    id="outlined-basic"
                                    label="Brand Name"
                                    variant="outlined"
                                    name="brandName"
                                    disabled
                                    value={saveForm.brandName}
                                />
                            </Col>
                            <Col item xs={12} md={6} >
                                <label className="text-sm">Tournament Name</label>
                                <TextField
                                    className="form-control"
                                    id="outlined-basic"
                                    label="Tournament Name"
                                    variant="outlined"
                                    name="brandName"
                                    disabled
                                    value={saveForm.tournamentName}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">

                            <Col item xs={12} md={4} >
                                <label className="text-sm">ITW Sales POC & Region </label>
                                <TextField
                                    className="form-control"
                                    id="outlined-basic"
                                    label="ITW Sales POC & Region"
                                    variant="outlined"
                                    name="selectTournament"
                                    type="textarea"
                                    Rows="3"
                                    value={saveForm.itwSalesPoc}
                                    onChange={(e)=>{setSaveForm({...saveForm, itwSalesPoc: e.target.value}); setItwSalesPocErr(null)}}
                                    error={itwSalesPocErr}
                                    helperText={itwSalesPocErr}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col item xs={12} md={2} >
                                <label className="text-sm">&nbsp; </label>
                                <FormControl fullWidth>
                                    <InputLabel className="overflow-visible" id="demo-simple-select-label"> Region</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Region"
                                        name="Region"
                                        value={saveForm.region}
                                        onChange={(e)=>setSaveForm({...saveForm, region: e.target.value})}
                                    >
                                        <MenuItem value="Bangalore">Bangalore</MenuItem>
                                        <MenuItem value="Delhi">Delhi</MenuItem>
                                        <MenuItem value="Mumbai">Mumbai</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col item xs={12} md={6} >
                                <label className="text-sm">Digital-360 POC </label>
                                <TextField
                                    className="form-control"
                                    id="outlined-basic"
                                    label="Digital-360 POC"
                                    variant="outlined"
                                    name="selectTournament"
                                    type="textarea"
                                    Rows="3"
                                    value={saveForm.digitalPoc}
                                    onChange={(e)=> {setSaveForm({...saveForm, digitalPoc: e.target.value}); setDigitalPocErr(null)}}
                                    error={digitalPocErr}
                                    helperText={digitalPocErr}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col item xs={12} md={12}>
                                <label className="text-sm mt-3">File Name</label>
                                <TextField
                                    className="form-control"
                                    id="outlined-basic"
                                    label="File Name"
                                    variant="outlined"
                                    name="brandName"
                                    type="textarea"
                                    Rows="3"
                                    value={saveForm.fileName}
                                    onChange={(e)=> {setSaveForm({...saveForm, fileName: e.target.value}); setFileNameErr(null)}}
                                    error={fileNameErr}
                                    helperText={fileNameErr}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col item xs={12} md={12} className="mt-3 text-right">
                                <Button onClick={()=> onSaveNewForm()}>{formLoading ? <CircularProgress style={{color: '#fff'}}/> : "SAVE"}</Button>
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </Modal>

            <Dialog
                className="NeatGridModal"
                open={openNeatDialog}
                onClose={handleNeatClose}
            >
                <DialogContent>
                <DialogContentText>
                    <FormGroup>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel 
                                    control={<Checkbox 
                                    checked={neatGrid.adFormat}
                                    name="adFormat"
                                    value={neatGrid.adFormat}
                                    onChange={handleNeatGrid}
                                    />} 
                                    label="Ad Format" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel 
                                    control={<Checkbox 
                                    checked={neatGrid.editLength}
                                    name="editLength"
                                    value={neatGrid.editLength}
                                    onChange={handleNeatGrid}
                                    />}
                                    label="Edit length" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.target}
                                    name="target"
                                    value={neatGrid.target}
                                    onChange={handleNeatGrid}
                                    />} 
                                    label="Targeting" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.duration}
                                    name="duration"
                                    value={neatGrid.duration}
                                    onChange={handleNeatGrid}
                                    />} 
                                    label="Duration" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel 
                                    control={<Checkbox 
                                    checked={neatGrid.startDate}
                                    name="startDate"
                                    value={neatGrid.startDate}
                                    onChange={handleNeatGrid}
                                    />}
                                    label="Start Date" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.endDate}
                                    name="endDate"
                                    value={neatGrid.endDate}
                                    onChange={handleNeatGrid}
                                    />}
                                    label="End Date" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.rateCardCpm10}
                                    name="rateCardCpm10"
                                    value={neatGrid.rateCardCpm10}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Rate Card CPM 10s" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel 
                                    control={<Checkbox 
                                    checked={neatGrid.rateCardCpm}
                                    name="rateCardCpm"
                                    value={neatGrid.rateCardCpm}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Rate Card CPM" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.discount}
                                    name="discount"
                                    value={neatGrid.discount}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Discount %" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.discountCpm}
                                    name="discountCpm"
                                    value={neatGrid.discountCpm}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Discounted Rate Card CPM" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.impressions}
                                    name="impressions"
                                    value={neatGrid.impressions}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Impressions" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.discountedOutlay}
                                    name="discountedOutlay"
                                    value={neatGrid.discountedOutlay}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Discounted Outlay" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.nonDiscountedOutlay}
                                    name="nonDiscountedOutlay"
                                    value={neatGrid.nonDiscountedOutlay}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Non Discounted Outlay" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox 
                                    checked={neatGrid.reach}
                                    name="reach"
                                    value={neatGrid.reach}
                                    onChange={handleNeatGrid}
                                    />}
                                label="Est Reach" />
                            </Grid>
                        </Grid>
                        
                    </FormGroup>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className="btn-danger" size="small" onClick={handleNeatClose}>
                    Cancel
                </Button>
                <Button className="btn-success"  size="small" onClick={fnExcelReportNeat}>Download</Button>
                </DialogActions>
            </Dialog>

           

            {/* Save new form */}
            <Footer />
        </DashboardLayout>

         {/* Preview */}
         <Dialog
         className="PreviewModal"
         open={openPreviewDialog}
         onClose={handlePreviewClose}
     >
         <DialogContent>
         <DialogContentText>
             <MediaPlanExcelPreview allForms={allForms} brandDetails={brandDetails}/>
         </DialogContentText>
         </DialogContent>
         <DialogActions>
         <div className="btn-print-hide">
            <Button className="btn-primary mx-2"  size="small" onClick={() => window.print()}>Print</Button>

            <Button className="btn-success mx-2"  size="small" onClick={fnExcelReport}>Download Excel</Button>

            <Button className="btn-danger mx-2" size="small" onClick={handlePreviewClose}>
                Close
            </Button>
         </div>
         </DialogActions>
     </Dialog>

    </>



    );
}

export default MediaPlanSheet;

