import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom'

import 'pages/common.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function MediaPlanExcelNeat(props) {
    const location = useLocation();
    useEffect(() => {
        fnExcelReport();
    //    console.log("props.allForms",props.allForms)
    }, []);

    function fnExcelReport() {
        var table = document.getElementById('excelTable'); // id of table
        var tableHTML = table.outerHTML;
        var fileName = props.fileName === null ? 'download.xls' : `${props.fileName}.xls`;
      
        var msie = window.navigator.userAgent.indexOf("MSIE ");
      
       
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          dummyFrame.document.open('txt/html', 'replace');
          dummyFrame.document.write(tableHTML);
          dummyFrame.document.close();
          dummyFrame.focus();
          return dummyFrame.document.execCommand('SaveAs', true, fileName);
        }
       
        else {
          var a = document.createElement('a');
          tableHTML = tableHTML.replace(/  /g, '').replace(/ /g, '%20'); // replaces spaces
          a.href = 'data:application/vnd.ms-excel,' + tableHTML;
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }

      function formatDate1(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da} ${mo}, ${ye}`
        return newDate;
    }

      const formatNumbers = (num, val) => {
        let numbers;
        let char;
        if (num < 1000) { numbers = num; char = '' }
        else if (num >= 1000 && num < 100000) { numbers = (num / 1000); char = ' K' }
        else if (num >= 100000 && num < 10000000) { numbers = (num / 100000); char = ' L' }
        else if (num >= 10000000) { numbers = (num / 10000000); char = ' CR' }
        return numbers.toFixed(1) + char
    }

    function numberFormatUS(x) {
        let nf = new Intl.NumberFormat('en-US');
        return nf.format(x);
    }

    function numberFormatIN(x) {
        let nf = new Intl.NumberFormat('en-IN');
        return nf.format(x);
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
           splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    // console.log("props.allForms",props.neatGrid)

    var Count = props.neatGrid; 
    const removedCol = Object.values(Count).filter(e=>!e).length;

    var colCount = Object.keys(Count).length;
    colCount =  colCount - removedCol;

    return (
            <div id="excelTable">
                <table class="table-small">
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <th colspan="3" style={{textAlign: 'center', width: '200px',backgroundColor: "#203764", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444",color: "#fff"}}><b>Client Name</b></th>
                                    <th colspan="3" style={{textAlign: 'left', width: '200px',padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>{props.brandDetails.brandName}</b></th>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{textAlign: 'center', width: '200px',backgroundColor: "#203764", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444",color: "#fff"}}><b>Agency</b></th>
                                    <th colspan="3" style={{textAlign: 'left', width: '200px',padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>ITW Catalyst Pvt Ltd</b></th>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{textAlign: 'center', width: '200px',backgroundColor: "#203764", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444",color: "#fff"}}><b>Property</b></th>
                                    <th colspan="3"style={{textAlign: 'left', width: '200px',padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>Jio</b></th>
                                </tr>
                            </table>
                        </td>
                        <td align="center" colspan="14">
                            <table>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><img style={{width:"200px"}} src="http://itwglobal.in/adweb/admin/extra/iologo.jpg" /></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    
                </table>
                <table>
                    <tr><td colspan={colCount} rowspan="3">&nbsp;</td></tr>
                </table>
                <table>
                {props.allForms?.map((x,i)=> 
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <td style={{backgroundColor: "#305496", height: "27px"}} colspan={colCount + 2}>&nbsp; </td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</td>
                                    <th style={{border: "thin solid #444", backgroundColor: "#203764", height: "27px", fontWeight: 200,fontSize: "13px", textAlign: "center",color: "#fff"}} colspan={colCount}>
                                    Inventory Buy Package - {props.brandDetails.tournamentName !== null ? props.brandDetails.tournamentName : ""} | {x?.outlay !== null ? formatNumbers(x?.outlay) : 0} | Option {x.id + 1}
                                    </th>
                                    <td style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</td>
                                </tr>
                                
                                <tr>
                                    <td style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</td>
                                   {props.neatGrid.adFormat && <th style={{width: 130,textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp; &nbsp;  Ad Format  &nbsp; &nbsp;</b></th>}
                                   {props.neatGrid.editLength && <th style={{width: 130, textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Edit length &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.target && <th style={{width: 130, textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp;Targeting &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.duration && <th style={{width: 130,textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp; &nbsp; Duration &nbsp; &nbsp;</b></th>}
                                   {props.neatGrid.startDate && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Start Date &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.endDate && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp;End Date &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.rateCardCpm10 && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Rate Card CPM 10s &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.rateCardCpm && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Rate Card CPM &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.discount && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Discount % &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.discountCpm && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Discounted CPM &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.impressions && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Booked Impressions &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.discountedOutlay && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Discounted Outlay &nbsp;&nbsp;</b> </th>}
                                   {props.neatGrid.nonDiscountedOutlay && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Non Discounted Outlay &nbsp;&nbsp;</b></th>}
                                   {props.neatGrid.reach && <th style={{textAlign: "center",backgroundColor: "#305496", fontSize: "10px", fontWeight: 200,border: "thin solid #444", height: "27px", fontWeight: 500, color: "#fff"}}><b>&nbsp;&nbsp; Estimated Reach &nbsp;&nbsp;</b></th>}
                                   <td style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</td>
                                </tr>
                                {x?.forms?.map((item, index) =>
                                <tr>
                                    <th style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</th>
                                    {props.neatGrid.adFormat && <th style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item?.adFormat}</th>}
                                    {props.neatGrid.editLength && <th style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item?.editLength}</th>}
                                    {props.neatGrid.target && <th style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.target === "ROS" ? "ROS" : item.targetRegion}</th>}
                                    {props.neatGrid.duration && <th  align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.duration === "ODI_only" ? "ODI Only" : item.duration === "custom" ? titleCase(item.customDuration) : titleCase(item.duration.split("_").join(' '))}</th>}
                                    {props.neatGrid.startDate && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item?.startDate ? formatDate1(item.startDate) : formatDate1(new Date())}</th>}
                                    {props.neatGrid.endDate && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item?.endDate ? formatDate1(item.endDate) : formatDate1(new Date())}</th>}
                                    {props.neatGrid.rateCardCpm10 && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.rateCardCpm10}</th>}
                                    {props.neatGrid.rateCardCpm && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.rateCardCpm}</th>}
                                    {props.neatGrid.discount && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.discount}</th>}
                                    {props.neatGrid.discountCpm && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.discountedCpm}</th>}
                                    {props.neatGrid.impressions && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.adFormat === "Squeeze Up" ? item.impressions : numberFormatUS(item.impressions)}</th>}
                                    {props.neatGrid.discountedOutlay && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{numberFormatIN(item.discountedOutlay)}</th>}
                                    {props.neatGrid.nonDiscountedOutlay && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{numberFormatIN(item.nonDiscountedOutlay)}</th>}
                                    {props.neatGrid.reach && <th align="center" style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}>{item.reach}</th>}
                                    <th style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</th>
                                </tr>
                                )}
                                {/* <tr>
                                    <td style={{backgroundColor: "#fbd256", height: "3px"}}>&nbsp;</td>
                                    <td style={{backgroundColor: "#fff", height: "3px", border: "thin solid #444"}} colspan={colCount}>&nbsp; </td>
                                    <td style={{backgroundColor: "#fbd256", height: "3px"}}>&nbsp;</td>
                                </tr> */}

                                <tr>
                                    <th style={{textAlign: "center",backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</th>
                                    <th style={{textAlign: "center",backgroundColor: "#8ea9db", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}} colspan={colCount-4} align="center"><b>Total</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#8ea9db", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatUS(x.forms?.map(item => /^\d+$/.test(item?.impressions) !== true ? 1 : item?.impressions).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#8ea9db", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.discountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#8ea9db", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.nonDiscountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#8ea9db", padding: "10px", height: "27px", fontSize: "12px", fontWeight: 200, border: "thin solid #444"}}><b></b></th>
                                    <th style={{backgroundColor: "#305496", height: "27px", width: "27px"}}>&nbsp;</th>
                                </tr>
                            
                                <tr>
                                    <td style={{backgroundColor: "#305496", height: "27px"}} colspan={colCount + 2}>&nbsp; </td>
                                </tr>
                            </table>
                            <table>
                                <tr><td colspan={colCount} rowspan="3">&nbsp;</td></tr>
                            </table>
                        </td>
                    </tr>
                )}
                </table>

                <div>
                    <ul>
                        <li><i><b>Note:</b></i></li>
                        <li><i><b> *100% Advance payment</b></i></li>
                        <li><i><b> *Applicable taxes extra.</b></i></li>
                        <li><i><b> *Creatives & CTA details to be shared 96 hours before the campaign commencement</b></i></li>
                        <li><i><b> *Campaign reports shall be shared on weekly basis from ad serving platform </b></i></li>
                        <li><i><b> *Daily tracking and 3rd party validation can be implemented (Amazon Sizmek) Service fee will be charged INR 2/CPM.</b></i></li>
                        <li><i><b> *Tracking can be enabled using Client attribution platform integrated with SonyLiv/Hotstar/Jio </b></i></li>
                        <li><i><b> *Invoice shall be raised post delivery of the campaign or Monthly basis, which ever earlier</b></i></li>    
                    </ul>
                </div>
            </div>           
    );
}

export default MediaPlanExcelNeat;